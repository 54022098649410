function App() {
  return (
    <div>
    <h1>
      Looking for Natalie Kwak's Personal Site? You're in the right place! This page is currently being updated, check back soon :<span>&#41;</span>
    </h1>
    <break></break>
    <h2>While you wait, why don't you check out Natalie's <a href="https://www.linkedin.com/in/natalie-kwak/">LinkedIn</a>, <a href="https://github.com/nataliekwak">GitHub</a>, or <a href="https://nataliekwak.com/KwakN_Fall24.pdf">resume</a>?</h2>
    </div>
  );
}

export default App;
